import { Header,  GalleryAWS } from '../../components';
import './fatDissolving.page.css';

const FatDissolving = () => {

    return (
        <div>
            <Header title="Fat Dissolving" />
            <div className="page-section">
                <div className="container">
                    <div className="max">
                        <section id="WhatisFatDissolvingTreatment">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>What is Fat Dissolving Treatment?</h3>
                                    <p className="standardText">Fat Dissolving treatment is an injectable treatment designed to target fatty deposits. By injecting a product
                                        containing Sodium Deoxycholate such as AQUALYX it breaks down fat cells for a smoother and slimmer
                                        profile.</p>
                                    <p className="standardText">Sometimes diet and exercise are not enough to produce the shape and profile we want and some stubborn
                                        areas of fat remain. This is where fat dissolving : intralipotherapy can be useful. Once injected the fat cells
                                        start to break down and are expelled normally by the body.
                                    </p>

                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Which areas can be treated?</h3>
                                    <ul className="standardText">
                                        <li>Double Chin</li>
                                        <li>Arms</li>
                                        <li>Jowls</li>
                                        <li>Stomach</li>
                                        <li>Hips (love handles)</li>
                                        <li>Outer thighs</li>
                                        <li>Arms</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>How long does the procedure take?</h2>
                                    <p className="standardText">The procedure time is usually 20-30 minutes.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>What does the procedure involve?</h2>
                                    <p className="standardText">Following a consultation with Dr Hazlehurst to discuss your aesthetic concerns the area to be treated will
                                        be marked up with a white pencil e.g. stomach or chin. The area will be sterilised with sterilising solution.
                                        Some numbing injections of lidocaine will be injected to make the area more comfortable. A cannula is
                                        inserted into the fat layers and the fat dissolving product is injected at the desired points. The product is
                                        then massaged into the area.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>How many treatments will be needed?</h2>
                                    <p className="standardText">This depends on the area being treated and the volume of the fat deposit being treated. One session will
                                        make a difference but usually between 2-6 treatments will be recommended for full results.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>When will I see results?</h2>
                                    <p className="standardText">You will notice a reduction in fat deposits in the treated area in the following weeks after your initial visit.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Does it hurt?</h2>
                                    <p className="standardText">The area will be numbed with numbing injections (local anaesthetic lidocaine) prior to treatment to make
the process more comfortable, only mild discomfort during the procedure is expected.</p>
                                    <p className="standardText">The area can be sore for a couple of days after the treatment.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>What are the possible side effects?</h2>
                                    <p className="standardText">You may develop some bruising and or minor swelling and redness of the treated area in the first few days.
                                        You may experience itching, burning sensations, feelings of pressure and minor aches and pains.</p>
                                    <p className="standardText">This is due to the inflammatory reaction that the fat dissolving product triggers.</p>
                                    <p className="standardText">Recovery time up to 7 days.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Are the results permanent?</h2>
                                    <p className="standardText">The fat cells that have been destroyed will not return or redevelop but it is important to maintain a healthy
                                        lifestyle of a healthy diet and exercise so that the remaining fat cells do not increase in size.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Who is fat dissolving not suitable for?</h2>
                                    <p className="standardText">Fat dissolving treatment is not to be used during pregnancy of breast feeding, under 18 year olds or any
                                        allergies to the ingredients or a history of anaphylaxis.</p>
                                    <p className="standardText">It is also inappropriate for patients with diabetes, severe allergies, autoimmune diseases, acute or chronic
                                        skin diseases in the affected area, on blood thinning agents, lymphatic disorders, renal disorders, keloid
                                        scarring and any other severe organic diseases.</p>
                                    <p className="standardText">Your suitability for treatment will be assessed during your consultation with Dr Hazlehurst.</p>
                                </div>
                            </div>
                        </section>
                        <section id="WhatisFatDissolvingTreatment">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>After care advice</h3>
                                    <ul className="standardText">
                                        <li>Avoid direct heat (sauna, sun etc) or severe cold and swimming pools for 3 days.</li>
                                        <li>Avoid taking anti-inflammatory drugs (e.g. ibuprofen).</li>
                                        <li>Avoid applying makeup to the area for at least 12 hours.</li>
                                        <li>Avoid physical exercise for 4 - 5 days.</li>
                                        <li>Do not undergo another aesthetic treatment in the same area for 2 weeks.</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section id="fat-dissolving-gallery">
                            <div>
                                <GalleryAWS bucketName="aesthetics-image-repository" prefix="FatDissolvingGallery" />
                            </div>
                        </section>


                    </div>
                </div>
            </div>
        </div>
    )


}

export default FatDissolving