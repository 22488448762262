import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import {Carousel} from 'react-bootstrap';
import './carousel.css';
import AWSS3BucketServices from '../../services/awss3bucket.service';


const CarouselAWS = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loading , setLoading]= useState(true);

  useEffect(()  => {
    const fetchImages = async () =>  
    {
        try {
          const service = new AWSS3BucketServices();
            var images = await service.getItemsInBucket('aesthetics-image-repository','HomepageCarousel');

            images.shift();

            const imageUrls = images.map(async (image) => {

                const tags = await service.getObjectTagsList('aesthetics-image-repository', image.Key)
                let alt = '';
                let title ='';

                if(tags.find(x=>x.Key==='alt') !== undefined)
                    alt = tags.find(x=>x.Key==='alt').Value;
                if(tags.find(x=>x.Key==='title') !== undefined)
                  title = tags.find(x=>x.Key==='title').Value;

                return { 
                    key: `https://aesthetics-image-repository.s3.amazonaws.com/${image.Key}`,
                    alt: alt,
                    title: title
                }
            });
            const imagesWithTags = await Promise.all(imageUrls);

            setImageUrls(imagesWithTags);
            setLoading(false);

        }
        catch(error){
          setLoading(false);
        }
     
    }

    fetchImages();
    
  }, []);

  if(loading){
    return <div>loading...</div>
  }

  return (
    <div className="carousel-container">
        <Carousel  className="d-block w-50">
        {imageUrls.map((imageUrl, index) => (
          <Carousel.Item key={index}>
            <h2 className="image-header">{imageUrl.title}</h2>
            <Image className="d-block w-100" rounded={true} src={imageUrl.key} alt={imageUrl.alt}  caption="test" key={index} />
          </Carousel.Item>
        ))}
        </Carousel>
    </div>
  );
}

export default CarouselAWS;
