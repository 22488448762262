import React from 'react'
import './footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
        <div className="col-md-6">
          
        </div>
        <div className="col-md-6">
          <a href="./Terms">Terms and Conditions</a>
          <hr />
          <a href="./Privacy">Privacy</a>
          <hr />
          <a href="./Pricing">Pricing</a>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Footer