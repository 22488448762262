import React from 'react'
import './dermalFiller.css';
import { Banner,  GalleryAWS, Header } from '../../components';
import bannerData from "../../assets/config/banner/banner-dermalfiller.json";
import { useEffect } from 'react';
import {pageview} from 'react-ga';

const DermalFiller = () => {

  useEffect(() =>{
    pageview(window.location.pathname)
},[]);

  return (
    <div>
      <Header title="Dermal Fillers" shadow="false"/>
      <div className="page-section">
      <Banner bannerData={bannerData} />
        <div className="container">
          <div className="max">
            <h1>Dermal Fillers</h1>
            <br />
            <section id="whatAreDermalFillers">
              <div className="row">
                <div className="col-md-12">
                  <h3>What are dermal fillers?</h3>
                  <p className="standardText">Dermal fillers are soft injectable gels containing hyaluronic acid.</p>
                  <p className="standardText">The skin naturally contains hyaluronic acid which strongly attracts water. As the skin ages
                    the amount of hyaluronic acid decreases and this correlates with moisture loss and the
                    formation of lines and wrinkles.
                  </p>
                  <p className="standardText">Fillers are used to</p>
                  <ul className="standardText">
                    <li>Improve deep wrinkles</li>
                    <li>Restore volume loss</li>
                    <li>Improve folds</li>
                    <li>Enhance facial features</li>
                    <li>Lip augmentation</li>
                  </ul>
                  <p className="standardText">Popular dermal fillers include Juvaderm®, Restylane®, Teosyal®, Stylage® and Boletero®</p>
                  <p className="standardText">Fillers are non-animal based and they are not permanent.</p>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="durationEnhancement">
              <div className="row">
                <div className="col-md-12">
                  <h3>How Long do Fillers last?</h3>
                  <p className="standardText">Fillers usually last 6-18 months depending on the filler used and your body's metabolism of the filler.
                    The body breaks down the filler naturally over time.
                  </p>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="Acheive">
              <div className="row">
                <div className="col-md-12">
                  <h1>Lip Enhancement</h1>
                  <p className="standardText">Lips naturally lose volume from the age of 18 years onwards.
                    Dermal fillers that are hyaluronic based are used to achieve beautiful, fuller, more youthful
                    looking lips.
                    Lip fillers are not just used for volume enhancement, they can hydrate and replace volume
                    loss associated with ageing while still looking natural.
                  </p>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="lipfillerInjections">
              <div className="row">
                <div className="col-md-12">
                  <h3>What can lip filler injections achieve?</h3>
                  <ul className="standardText">
                    <li>Enhancement (Augmentation)</li>
                    <li>Rehydration</li>
                    <li>Correct lip asymmetry </li>
                  </ul>
                </div>
              </div>
              <div className="ba_container">
                <GalleryAWS bucketName="aesthetics-image-repository" prefix="DermalFillerEnhancementsGallery" />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h4>Lip Filler 0.5ml</h4>
                  <p className="standardText">
                    Using 0.5ml of lip filler provides a subtle result, lips will look slightly fuller, rehydrated and rejuvenated.
                  </p>
                </div>
                <div className="col-md-6">
                  <h4>Lip Filler 1ml</h4>
                  <p className="standardText">Using 1ml of lip filler produces a noticeable size increase, especially for originally smaller lips.</p>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="whatToExpect">
              <div className="row">
                <div className="col-md-12">
                  <h3>What to expect during the appointment.</h3>
                </div>
                <div className='row'>
                  <div className="col-md-9">
                    <p className="standardText">Dr Hazlehurst will talk you through your cosmetic aims and come up with a treatment plan suitable for you, that you are happy with.</p>
                    <p className="standardText">Photos of your lips will be taken to document the before appearance.</p>
                    <p className="standardText">An anaesthetic cream will be applied to your lips to make the procedure more comfortable.</p>
                    <p className="standardText">The lips will be cleansed with an antiseptic solution.</p>
                    <p className="standardText">Once you are comfortable on the couch, small injections of the filler will be made at various points around your upper and lower lips.</p>
                    <p className="standardText">The injecting time usually takes 20 minutes.
                    </p>
                    <p className="standardText">Once the procedure is completed you will be given the aftercare advice and you can carry on with your day.</p>
                    <br />
                    <br />
                    <h3>Before your filler appointment</h3>
                    <ul>
                      <li>
                        <p className="standardText">Ensure any scheduled vaccines including the Covid Vaccines are given 3-4 weeks apart from your filler treatment.</p>
                      </li>
                      <li>
                        <p className="standardText">To reduce the amount of bruising and swelling at the injection sites:</p>
                        <p className="standardText"><b>2 days before:</b></p>
                      </li>
                      <li>
                        <p className="standardText">Avoid over the counter blood thinning medications including aspirin and ibuprofen. (Do not stop any prescribed medication from your doctor).</p>
                      </li>
                    </ul>

                  </div>
                  
                </div>
              </div>
            </section>

            <section id="beforeAppointment">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    <li>
                      <p className="standardText">Avoid certain supplements including Gingko biloba, St John's Wort, primrose oil, ginseng, garlic, vitamin E.</p>
                    </li>
                    <li>
                      <p className="standardText">Avoid topical products.</p>
                    </li>
                    <li>
                      <p className="standardText">Avoid hair removal, waxing, bleaching, tweezing the area to be treated.</p>
                    </li>
                  </ul>
                  <p className='standardText'><b>24 hours before: </b></p>
                  <ul>
                    <li>
                      <p className="standardText">Do not drink alcohol or take over the counter aspirin or ibuprofen.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="sideEffects">
              <div className="row">
                <div className="col-md-12">
                  <h3>What are the possible side effects / risks of fillers?</h3>
                  <p className="standardText">
                    The swelling stage starts straight away after the filler is injected and increases over the next 24-72 hours. It takes fillers 2 weeks to
                    settle fully into the area and the residual swelling to subside. Its advised to massage your lips daily during the first 2 weeks.
                  </p>
                  <ul>
                    <li><p className="standardText">Some redness, swelling and bruising. Normally settles within a few days - 2 weeks.</p></li>
                    <li><p className="standardText">Infection - rare</p></li>
                    <li><p className="standardText">Hypersensitivity, allergic reactions - rare</p></li>
                    <li><p className="standardText">Visible raised areas, bumpiness around the site.</p></li>
                    <li><p className="standardText">Nodules, granulomas even after filler dissolved - rare</p></li>
                    <li><p className="standardText">Prolonged discolouration of the skin.</p></li>
                    <li><p className="standardText">Asymmetry, overcorrection or under correction.</p></li>
                    <li><p className="standardText">Granulomas or nodules - rare</p></li>
                    <li><p className="standardText">Vascular occlusion: filler injected into blood vessels, causing blockage which could lead to tissue death, scarring or blindness - very rare</p></li>
                  </ul>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="aftercare">
              <div className="row">
                <div className="col-md-8">
                  <h3>Lip Filler Aftercare</h3>
                  <p className="standardText">
                    Lips may be tender and swollen after treatment.
                  </p>
                  <ul>
                    <li><p className="standardText">Avoid applying makeup for 24-48 hours after treatment.</p></li>
                    <li><p className="standardText">Avoid drinking alcohol for 24 hours, as it may cause bruising.</p></li>
                    <li><p className="standardText">Avoid vigorous exercises for 24 hours.</p></li>
                    <li><p className="standardText">Massage the lips for 7-14 days after the treatment to avoid forming lumps.</p></li>
                  </ul>
                </div>
              </div>
            </section>
            <br />
            <br />
            <section id="nasolabialFolds">
              <div className="row">
                <div className="col-md-12">
                  <h1>Nasolabial Folds (nose to mouth)</h1>
                  <p className="standardText">
                    Nasolabial folds are laugh lines that run from your nose to the corners of your mouth. Your cheeks
                    droop downwards and the nasolabial folds deepen with age and appear more noticeable.
                    Dermal fillers injected into the nasolabial fold soften the look of the fold and lift the laugh lines.
                    Dr Hazlehurst decides the most suitable technique, to improve your laugh lines, to produce the
                    desired results.
                  </p>
                </div>
                <div className="row">
                <GalleryAWS bucketName='aesthetics-image-repository' prefix='FillerPage-NasolabialFoldsGallery' />
              </div>
              </div>
            </section>
            <section id="marionnetteSection">
              <div className="row">
                <div className="col-md-12">
                  <h1>Marionnette Lines</h1>
                  <p className="standardText">
                    
                  </p>
                </div>
              </div>
              <br />
              <div className="row">
                <GalleryAWS bucketName="aesthetics-image-repository" prefix="FillerPage-MarionetteGallery" />
              </div>
            </section>
            <section id="chin-filler-gallery">
              <div className="row">
                <div className="col-md-12">
                  <h1>Chin Fillers</h1>
                </div>
              </div>
              <br />
              <div className="row">
                <GalleryAWS bucketName="aesthetics-image-repository" prefix="chin-filler-gallery" />
              </div>
            </section>
            <section id="lips-corner-mouth-filler-gallery">
              <div className="row">
                <div className="col-md-12">
                  <h1>Lips & Corner Mouth Fillers</h1>
                </div>
              </div>
              <br />
              <div className="row">
                <GalleryAWS bucketName="aesthetics-image-repository" prefix="lips-corner-mouth-filler-gallery" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DermalFiller