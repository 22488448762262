import { useEffect, useState, React } from "react";
import { Header } from "../../components";
import InstagramService from "../../services/instagram.service";
import Moment from 'react-moment';
import './blog-page.css';
const Blog = () => {
    const [blogList, setBlogList] = useState([])
    const instaSvc = new InstagramService();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const articles = await instaSvc.getArticle();
                setBlogList(articles);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();

    }, []);

    const showBlogPosts = (blogList) => {

        console.log("blogList", blogList)

        if (blogList.length === 0) {
            return <div>Loading...</div>; // Or any other loading indicator
        }

        return blogList.map((blog) => (
            <div className="blog-article">
                <div key={blog.id} className="blog-title"><h2>{blog.title}</h2></div>
                <div><Moment format="MMMM D, YYYY HH:mm">{blog.created_at}</Moment></div>
                <div className="blog-content standardText">{blog.content}</div>
                
            </div>
        ));
    };


    return (
        <div>
            <Header title="Blog Page" ></Header>
            <div className="page-section">
                <div className="container">
                    <div className="blog-page">{showBlogPosts(blogList)}</div>
                </div>
            </div>
        </div>
    )
}

export default Blog