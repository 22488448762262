import {React, useState} from 'react'
import './navbar.css';
import {Nav, NavDropdown, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo3.png';

const NavbarDR = () => {

  const [expanded, setExpanded] = useState(false);

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };
  
  return (
    <div className="navigation-back" >
      <div className="container">
      <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark">
      <Navbar.Brand>
        <Link  className="nav-link nav-text" to="/"><img src={Logo}  width="200px" alt="dr hazlehurst aesthetics logo" /></Link>
      </Navbar.Brand>
      <Navbar.Toggle onClick={handleNavbarToggle} />
      <Navbar.Collapse in={expanded}>
        <Nav >
          <Link className="nav-text" to="/" onClick={handleLinkClick}>Home</Link>
          <NavDropdown title="Services" id="basic-nav-dropdown">
            <NavDropdown.Item>
              <Link className="nav-text-dropdown" to="/AntiWrinkleInjections" onClick={handleLinkClick}>Anti-Wrinkle Injections</Link>
              <Link className="nav-text-dropdown" to="/DermalFiller" onClick={handleLinkClick}>Dermal Fillers</Link>
              <Link className="nav-text-dropdown" to="/Microneedling" onClick={handleLinkClick}>Microneedling</Link>
              <Link className="nav-text-dropdown" to="/Skinpeels" onClick={handleLinkClick}>Skin peels</Link>
              <Link className="nav-text-dropdown" to="/FatDissolving" onClick={handleLinkClick}>Fat Dissolving</Link>  
              <Link className="nav-text-dropdown" to="/SkinBoosters" onClick={handleLinkClick}>Skin Boosters</Link>
              <Link className="nav-text-dropdown" to="/SkinLesion" onClick={handleLinkClick}>Skin Lesions</Link>
              <Link className="nav-text-dropdown" to="/Polynucleotides" onClick={handleLinkClick}>Polynucleotides</Link>
              <Link className="nav-text-dropdown" to="/Cryotherapy" onClick={handleLinkClick}>Cryotherapy</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <Link className="nav-text" to="/About" onClick={handleLinkClick}>About</Link>
          <Link className="nav-text" to="/Pricing" onClick={handleLinkClick}>Pricing</Link>
          <Link className="nav-text" to="/Contact" onClick={handleLinkClick}>Contact</Link>
          {/* <Link className="nav-text" to="/Blog" onClick={handleLinkClick}>Blog</Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  </div>

  )
}
export default NavbarDR 