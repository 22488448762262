import './header.css';

const Header = (props) => {
    return <ShadowHeader shadow={props.shadow} title={props.title}></ShadowHeader>
}

function ShadowHeader ({shadow, title})  {
    if(shadow === 'false')
    {
        return <header><h2>{title}</h2></header>
    }
    return (
        <header className="shadow">
            <h2>{title}</h2>
        </header>
    )

}


export default Header;