import React from 'react'
import './about.css';
import i1 from '../../assets/abt_k_Clinic_pt.jpeg';
import Header from '../../components/header/Header';
import { GalleryAWS } from '../../components';

const About = () => {
  return (
    <div><Header title="About" />
      <div className="page-section">
            <div className="container">
                <div className="max">
                  <div className="row">
                    <div className="col-md-8">
                    <p className="standardText" >Dr Kathryn Hazlehurst is a Medical Doctor.
                    She completed her Medical degree at the University of Birmingham with honours in 2006.
                    She works as a GP in Cheshire.</p>
                    <p  className="standardText">
                    She has been professionally trained by experts in the field of Medical Aesthetics at the Harley Street
                    Institute in London in botulinum toxin (Botox) and dermal fillers.
                    She has also undertaken advanced training in Botox and Fillers and Polynucleotides with the Skin Viva Academy.
                    She is also trained in recognising and managing complications of aesthetic treatments and registered with CMAC.
                    She is GMC registered and fully insured to carry out Medical Aesthetic treatments.
                    </p>
                    <p  className="standardText">
                      She has been listed in the renowned and well respected "The Tweakments Guide".
                    </p>
                    <GalleryAWS bucketName="aesthetics-image-repository" prefix="AboutMeGallary" />
                    </div>
                    <div className='col-md-4'>
                      <img src={i1} className="imgborder" alt="Appying Botox to client frown line." width="100%" />
                    </div>

                  </div>

                  
                </div>
            </div>
          </div>
    </div>
  )
}

export default About