import { useEffect, useState } from "react";
import AWSS3BucketServices from "../../services/awss3bucket.service";
import './galleryAWS.css';


const GalleryAWS = (props) =>{

    const [imageObjects, setImageObjects] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            try{
                const bucketService = new AWSS3BucketServices();
                var images = await bucketService.getItemsInBucket(props.bucketName,props.prefix);
                images.shift();
                const imageUrls = images.map(async (image) => {
                    return { 
                        key : `https://aesthetics-image-repository.s3.amazonaws.com/${image.Key}`,
                    };
                });

                const finalImages = await Promise.all(imageUrls)

                setImageObjects(finalImages);
                setLoading(false);

            }
            catch(error)
            {
                console.log(error)
                setLoading(false);
            }
        }

        fetchImages();

    }, [])


    if(loading) { return <div>loading Gallery...</div>}

    return(<div className='image-gallery'>
         {imageObjects.map((image, index) => (
            <li key = {index}>
                <img src={image.key} className="flex-item imgborder" alt="example"/>
            </li>
         ))}

        
    </div>)
}

export default GalleryAWS;