const Privacy = () => {
    return (
        <div className="page-section">
            <div className="container">
                <div className="max">
                    <h1>Privacy Policy</h1>
                    <p className="standardText" >Dr Hazlehurst Aesthetics values your privacy and want to be clear about the data we collect, how we use it and your
                        rights to control that information. This policy reflects the high standards established by the General Data Protection
                        Regulation (GDPR), a set of laws passed in the European Union. It applies to information collected by us, or provided
                        by you, during your appointment, via email, our website, or in any other way including over the phone.
                        We are committed to protecting the privacy of our patients/clients. This privacy policy is intended to inform you on how
                        we gather, define and use your data.</p>
                    <p className="standardText" >All your personal data will be held and used in accordance with GDPR and national laws implementing GDPR and any
                        legislation that replaces it in whole or in part relating to the protection of personal data.</p>
                    <br />
                    <h2>Information Collection</h2>
                    <p className="standardText" >We do not collect personally identifiable information about you, except when you provide it to us directly or through a
                        third party. For example, if you subscribe to our mail newsletter, complete an online survey, leave a review or book a
                        treatment with us, or consent to have a treatment with us.</p>
                    <br />
                    <h2>The Information we Record via our Website</h2>
                    <p className="standardText" >When you visit our websites (via a computer, mobile or hand-held device) you may provide us with personal information
                        including your name, address, contact details and financial data.
                        This information is gathered when you register or book an appointment online, email the Clinic, or sign up for our
                        newsletter, complete an online survey or leave a review.</p>
                    <br />
                    <h2>Cookies</h2>
                    <p className="standardText" >Our websites use the Google Analytics cookie. The Google Analytics cookie allows us to see information on user
                        website activity including, but not limited to page views, referral and average time spent on the website. The information
                        is depersonalised and is displayed as numbers, meaning it will not be tracked back to individuals, which helps to protect
                        your privacy. Using Google Analytics we can see what content is popular on our website, and strive to ensure you have
                        the best user experience possible.</p>
                    <p className="standardText" >Our websites may contain links to or from other websites. Please be aware that we are not responsible for the privacy
                        practices of other websites. This privacy policy applies only to the information we collect on this Site. We encourage you
                        to read the privacy policies of other websites you link to from our site or otherwise visit.</p>
                    <br />

                    <h2>The information we Record via Emails</h2>
                    <p className="standardText"> When you correspond with Dr Hazlehurst Aesthetics by email, we may need to retain the content of your email and any
                        photographs supplied together with our replies, as they form part of your medical records.</p>
                    <br />
                    <h2>The Information We Record During Your Consultations & Treatment Appointments</h2>
                    <p className="standardText"> When you visit the Dr Hazlehurst Aesthetics Clinics you provide personal information including your name, address,
                        date of birth, contact details and medical history.</p>
                    <p className="standardText">During your consultation medical notes are taken, which may include any allergies, operations and medication. We also
                        record treatment data which may include details of treatments or procedures you have had done.
                        Depending on the treatment sometimes photography and prescriptions may be required. This will form part of your
                        treatment/medical records.</p>
                    <p className="standardText">During your visit you may be asked to read and sign consent and after care forms which form part of your
                        treatment/medical records. During your treatment we will record treatment settings, outcomes and may take
                        photographs which form part of your treatment / medical records.</p>
                    <br />
                    <h2>How We Use Your Information</h2>
                    <p className="standardText"> Your personal details and medical records are for legitimate purposes and ensure we are able to :</p>
                    <ul>
                        <li>Provide the best possible care</li>
                        <li>Provide Beauty, Medical and Aesthetic treatments safety</li>
                        <li>Identify any contraindications you may have for specific treatments</li>
                        <li>Diagnose medical concerns, provide treatment plans and write prescriptions</li>
                        <li>Maintain an accurate appointment diary</li>
                        <li>Confirm your appointment by text, email or phone</li>
                        <li>To answer your questions by email or phone</li>
                        <li>Keep you up to date on news and treatments offered by the Clinic</li>
                        <li>To contact you for post-treatment follow up and care, including survey requests in order to improve our service</li>
                    </ul>
                    <p className="standardText">You have a responsibility to inform us if any of your details such as name, address, contact numbers change, so our
                        records are accurate and up to date for you. Generally, we do not rely on consent as a legal ground for processing your
                        personal data, other than in relation to sending marketing communications to you via email.</p>
                    <br />

                    <h2>Taking Payments</h2>
                    <p className="standardText">We take card payments using a Sum Up card reader in the clinic and via the Fresha booking platform for online
                        payments. These third parties have access to your Transaction Data and have their own GDPR compliant policies.</p>
                    <br />

                    <h2>Appointment System</h2>
                    <p className="standardText">We use Fresha for our appointment diary, this enables you to book appointments online, it also sends automatic
                        appointment confirmations and reminders. We store your contact details such as name, address, email and phone
                        numbers on here in order to identify you and send you confirmations and reminders and in case we need to inform you
                        of anything related to your appointment.</p>
                    <br />

                    <h2>Treatments requiring prescriptions</h2>
                    <p className="standardText">At Dr Hazlehurst Aesthetics some treatments require a prescription by a doctor. These prescriptions will need to be sent
                        to a pharmacy in order to provide your agreed treatment. Opting out of sharing your information with these providers
                        may affect our ability to treat you. All our suppliers have entered into appropriate confidentiality obligations and/or
                        contractual data processing clauses with us.</p>
                    <br />
                    <h2>How We Maintain Confidentiality of your Records</h2>
                    <p className="standardText">Every member of staff has a legal obligation to keep information about you confidential.
                        We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or
                        accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those
                        employees, agents, contractors and other third parties who have a business need to know such data. They will only
                        process your personal data on our instruction and they are subject to a duty of confidentiality.</p>
                    <p className="standardText">We have put in place procedures to deal with any suspected personal data breach and will notify you and any
                        applicable regulator of a breach where we are legally required to do so.</p>
                    <p className="standardText">Depending on the service you use your records are locked away or stored electronically in a GDPR compliant software
                        system. All digital data is secure and is highly protected from unauthorised persons and is also protected from deletion
                        or malicious hacking.</p>
                    <br />
                    <h2>How We Share Your Information</h2>
                    <p className="standardText">We will only share your information if you have given us written permission to make/cancel appointments with a family
                        member/employee.
                        We do not sell our database to third parties.
                        We never share any information with third parties unless there is a genuine need for it, or we receive their request in
                        writing and we have your written consent.</p>
                    <br />
                    <h2>Disclosure of Personally Identifiable Information</h2>
                    <ul>
                        <li>
                            <h3>Fraud Protection and Compliance with Law</h3>
                            <p className="standardText">We may need to disclose your personal information or share your personal information in order to comply with
                                any legal or regulatory requirement, obligation or request. This includes the police for the prevention or
                                investigation of a crime, HMRC, or our Insurers, legal advisors or other third parties who need access to it in
                                the context of managing, investigating or defending claims or complaints.</p>
                        </li>
                        <li>
                            <h3>Service Providers</h3>
                            <p className="standardText">We may retain other companies and individuals to perform functions consistent with our Privacy Policy on our
                                behalf. Examples include customer support specialists, webhosting companies, credit card processing
                                companies, fulfilment companies (e.g., companies that fill product orders or coordinate mailings), data analysis
                                firms and email service providers. Such third parties may be provided with access to personally identifiable
                                information needed to perform their functions, but may not use such information for any other purpose. We
                                require all third parties to respect the security of your personal data and to treat it in accordance with the law.</p>
                        </li>
                    </ul>
                    <br />
                    <h2>How long do we hold your information</h2>
                    <p className="standardText">We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the
                        purposes of satisfying any legal, accounting, or reporting requirements.
                        By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction
                        Data) for six years after they cease being customers for tax purposes.
                        As a medical clinic we are required to hold medical records for ten years from your last treatment.
                        Job applications and unsuccessful interview candidates – 6 months
                        Employee Records – Indefinitely
                        Marketing Enquiries and Emails – 6 months</p>
                    <br />
                    <h2>Your Rights</h2>
                    <p className="standardText">
                        You have the right to withdraw your consent at any time by contacting us via email or letter. We will no longer contact
                        you although medical records must be retained for ten years. Where we are required to collect personal data by law, or
                        under the terms of the contract between us and you do not provide us with that data when requested, we may not be
                        able to perform the contract (for example, to deliver goods or services to you). If you don’t provide us with the requested
                        data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.
                        You have the right to request a copy of your medical records and this request must be put in writing and signed by the
                        patient. We are required to respond to you within 30 days.
                        You have the right to have information updated or corrected if you feel it is inaccurate, incomplete or out of date. This
                        request must put in writing and signed by the patient.
                    </p>
                    <br />
                    <h2>Changing our Privacy Policy</h2>
                    <p className="standardText">
                        Our privacy policy will be reviewed regularly and updated as needed or as required by law.
                        The revised policy will be displayed on our website. Where necessary, you may be asked to sign the consent form
                        again.
                    </p>
                    <br />
                    <h2>Objections &amp; Complaints</h2>
                    <p className="standardText">
                        Our Data Protection Officer is responsible for ensuring the Clinic keeps your information secure and confidential.
                        If you are still unhappy you can then complain to the Information Commissioners Office (ICO) at www.ico.gov.uk or
                        telephone 0303 123 1113.
                    </p>
                    <br />
                    <h2>Data Breaches</h2>
                    <p className="standardText">
                        Dr Hazlehurst Aesthetics has a data breach policy and, in the unlikely event of a breach occurring, a further
                        investigation will be held. Lessons learnt will be added to the policy and the relevant supervising bodies notified if
                        required.
                    </p>
                    <br />
                    <h2>Changes to our privacy policy</h2>
                    <p className="standardText">
                        Any changes we may make to our privacy policy in the future will be posted on this page.
                    </p>
                    <br />
                    <p className="standardText">This privacy policy was last updated on 29 th September 2022.</p>
                </div>
            </div>
        </div>
    )
}

export default Privacy;