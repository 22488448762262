import { Header, PriceCard } from '../../components';
import './price.page.css';

const Pricing = () => {
    return (
        <div>
            <Header title="Pricing" shadow="true"></Header>
            <div className="page-section">
                <div className="container">
                    <div className="price-container">
                        <h2>Anti-wrinkle Injections</h2>
                        <ul>
                            <li>Areas of upper face anti-wrinkle injections (botox) administered by Dr Kathryn.</li>
                            <li>Areas to choose from: forehead, frown, crow’s feet, arch lift/botox brow lift, bunny lines, jelly roll (lower eye lid). </li>
                            <li>Minimum age 21 years.</li>
                            <li>£30 supplement per area for men as stronger muscles & more botulinum toxin required. </li>
                        </ul>
                        <div className="card-grid-2">
                            <PriceCard
                                title="1 area"
                                price="£170.00">
                            </PriceCard>
                            <PriceCard
                                title="2 areas"
                                price="£220.00">
                            </PriceCard>
                            <PriceCard
                                title="3 areas"
                                price="£260.00">
                            </PriceCard>
                            <PriceCard
                                title="4 areas"
                                price="from £300.00">
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Advanced anti-wrinkle Injection Treatments</h2>
                        <div className="card-grid-2">
                            <PriceCard
                                title="Nose tip lift with ‘botox’"
                                price="£170.00"
                                infos={[
                                    'Nose tip elevation with administration of botulinum toxin injections by Dr Hazlehurst.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Nasal flare correction"
                                price="£170.00"
                                infos={[
                                    'Nasal flare reduction using Botulinum toxin injection’s administered by Dr Hazlehurst.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Eyebrow arch lift"
                                price="£190.00"
                                infos={[
                                    'Eye brow arch lift with botulinum toxin injections administered by Dr Hazlehurst using a special advanced technique to lift the tail of the eyebrow.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Lip Flip"
                                price="£90.00"
                                infos={[
                                    'Lip Flip with Dr Kathryn Hazlehurst using  Botulinum toxin injections to give the upper lip a fuller appearance.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Gummy smile correction"
                                price="£190.00"
                                infos={[
                                    'Gummy smile correction with Botulinum toxin injections administered by Dr Hazlehurst.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            {/* <PriceCard
                                title="Downturned mouth correction with ‘botox’"
                                price="£165.00"
                                infos={[
                                    'Correction of downturned mouth by injection of botulinum toxin into the DAO muscles administered by Dr Hazlehurst.',
                                    'Minimum age 21 years.']}>
                            </PriceCard> */}
                            {/* <PriceCard
                                title="Dimpled chin correction"
                                price="£165.00"
                                infos={[
                                    'Smoothing of dimpled chin / reduction of golf ball chin by administering botulinum toxin injections by Dr Hazlehurst.',
                                    'Minimum age 21 years.']}>
                            </PriceCard> */}
                            <PriceCard
                                title="Jawline slimming"
                                price="£295.00"
                                infos={[
                                    'Facial jawline / masseter slimming with botulinum toxin injections.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Neck Lift and vertical bands treatment"
                                price="£295.00"
                                infos={[
                                    'Nefertiti neck lift. Anti-wrinkle injections to the neck area with Dr Hazlehurst to lift the neck and give the neck a smoother more youthful appearance by smoothing the vertical lines (platysmal bands).',]}>
                            </PriceCard>
                            <PriceCard
                                title="Armpit for Anti-sweating 'botox'"
                                price="£380.00"
                                infos={[
                                    'Cosmetic anti-sweating. ‘Botox’ injections to the armpits to reduce sweating administered by Dr Hazlehurst. Lasts up to 6 months.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Dermal Fillers</h2>

                        <div className="card-grid-2">
                            <PriceCard
                                title="Lip Enhancement with lip filler"
                                price="£260.00"
                                infos={[
                                    'Lip filler injected by a Doctor trained in Aesthetics, Dr Hazlehurst, for lip rejuvenation and enlargement. ']}>
                            </PriceCard>
                            <PriceCard
                                title="Dermal fillers nasolabial folds (nose to mouth)"
                                price="from £290.00"
                                infos={[
                                    'Dermal filler injected into the nasolabial folds (nose to mouth areas)']}>
                            </PriceCard>
                            <PriceCard
                                title="Dermal fillers Marionette Lines (mouth to chin)"
                                price="from £290.00"
                                infos={[
                                    'Dermal fillers to the marionette lines (mouth to chin).']}>
                            </PriceCard>
                            <PriceCard
                                title="Dermal filler for smokers lines. Above lip lines."
                                price="£260.00"
                                infos={[
                                    'Dermal filler Injections by a Doctor qualified in Medical Aesthetics, Dr Hazlehurst a for smokers lines. Vertical lines above upper lip.',]}>
                            </PriceCard>
                            <PriceCard
                                title="Cheek enhancement with dermal filler"
                                price="from £295.00"
                                infos={[
                                    'Sculpt, enhance and replace volume.',
                                    'Fill hollow areas and add definition. Lifting the face.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Jawline enhancement with dermal filler"
                                price="from £420.00"
                                infos={[
                                    'Define and enhance the jawline with dermal filler.',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Chin enhancement with dermal filler"
                                price="from £295.00"
                                infos={[
                                    'Chin enhancement awith dermal filler.',
                                    'Add definition, reshape, improve facial profile',
                                    'Minimum age 21 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Tear trough filler"
                                price="£420.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Temple hollows filler from £420.00"
                                price="£420.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Forehead filler"
                                price="£POA"
                                infos={[]}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Fat dissolving Injections</h2>
                        <div className="card-grid">
                        <PriceCard
                                title="Fat Dissolving Injections"
                                price="from £300.00"
                                infos={['areas: stomach, hips, chin, jowls, under arms']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Microneedling</h2>
                        <div className="card-grid">
                            <PriceCard
                                title="Microneedling medical grade face and neck with mesotherapy application"
                                price="£160.00"
                                infos={[]}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Chemical skin peel medical grade</h2>

                        <div className="card-grid">
                            <PriceCard
                                title="Chemical skin peel medical grade"
                                price="£75.00"
                                infos={[
                                    'A course of 3: £191']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Aesthetic consultation</h2>

                        <div className="card-grid">
                            <PriceCard
                                title="Aesthetic Consultation with Dr Kathryn"
                                price="£50.00"
                                infos={[
                                    'Aesthetic consultation with Dr Kathryn to discuss your aesthetic needs. The cost of this consultation will be deducted from your treatment with Dr Kathryn. ']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>

                    <div className="price-container">
                        <h2>Skin Boosters</h2>
                        <div className="card-grid-2">
                          
                            <PriceCard
                                title="Profhilo"
                                price="£290.00 per 2ml syringe"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Hydromax"
                                price="£260.00 per syringe"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Seventy hyal skin boosters"
                                price="£170.00 per 2ml syringe"
                                infos={['5 point face treatment', 'under eye', 'neck', 'chest', 'hand rejuvenation']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Polynucleotides</h2>
                        <div className="card-grid">
                            <PriceCard
                                title="Polynucleotides"
                                price="£290.00 per 2ml syringe"
                                infos={['under eye / around eyes','face', 'neck','chest', 'knees','Package £699 for 3 x 2ml syringe']}>
                            </PriceCard>
                        </div>
                    </div>

                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Package Offers</h2>

                        <div className="card-grid-2">
                            <PriceCard
                                title="Chemical skin peel medical grade 3 sessions"
                                price="£190.00"
                                infos={[
                                    '(Discount of £35).',
                                    'Minimum age 18 years.']}>
                            </PriceCard>
                            <PriceCard
                                title="Polynucleotides"
                                price="£699.00"
                                infos={['3 x 2ml syringe']}>
                            </PriceCard>
                        </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="price-container">
                        <h2>Skin Lesions</h2>

                        <div className="card-grid-2">
                            <PriceCard
                                title="2 Lesions Removal"
                                price="£140.00"
                                infos={[
                                    '2 lesion removal for skin tag/ spider naevus/ campbell de morgan/ milia/ 1 small patch of facial thread veins.',
                                    'including patch test & consultation.']}>
                            </PriceCard>
                            <PriceCard
                                title="Follow up treatments for one lesion."
                                price="£80.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Additional lesions"
                                price="£80.00"
                                infos={[
                                    'Per skin tag depending on size and amount']}>
                            </PriceCard>
                            <PriceCard
                                title="Full face"
                                price="£340.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Full neck"
                                price="£340.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Half back"
                                price="£340.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Both Armpits"
                                price="£340.00"
                                infos={[]}>
                            </PriceCard>
                            <PriceCard
                                title="Cryotherapy"
                                price="from £100.00"
                                infos={["Wart removal", "Verruca","Sebarrhaeic Keratosis","Benign pigment patch", "Follow up treatment at 4 weeks £50.00"]}>
                            </PriceCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Pricing;