import './review.css';

const Review = ({ data }) => {
    return (
        <div className="review-item">
            <h4>{data.author}</h4>
            <span className="review-date">{data.date}</span>{StarDisplay(data.stars)}<br />
            <span>
                <i className="fa-solid fa-quote-left open-quote"></i>
                {data.comment}
                <i className="fa-solid fa-quote-right close-quote"></i>
            </span>
        </div>
    )
}

const StarDisplay = (value) => {
    const stars = [];

    

    for (let i = value; i > 0; i--) {
        stars.push(
            i           
        );
    }
    return <span>  {stars.map((i, key) => (
        <i className="fa-solid fa-star gold" key={key}></i>
    ))} </span>
}


export default Review;
