const PriceCard = (props) => {
    return(
        <div className="price-card">
            <div className="price-card-header">
                <h2 className="price-card-title">{props.title}</h2><p>{props.price}</p>
            </div>
            <div className="price-card-content">
                <ul>
                    { props.infos ? props.infos.map(function(info, i){
                        return <li>{info}</li>
                    }): ''}
                </ul>
            </div>    
        </div>
    )
} 


export default PriceCard;