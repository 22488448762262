const Terms = () => {

    return (
        <div className="page-section">
            <div className="container">
            <div className="max">
            <h1>Terms and Conditions</h1>
            <h2>Appointment Cancellation Policy</h2>
            <p className="standardText" >
                Dr Kathryn Hazlehurst requires 48 hours notice for appointment cancellations for both
                treatments and consultations.
                There is a charge of 30% of the treatment cost for cancellations 24 hours or less before the
                appointment.
                There is a charge of 50% of the treatment cost for clients that do not attend their booked
                appointment.
            </p>
            <p className="standardText" >
                No refunds for deposits or treatments that have been paid for.
            </p>
            <p className="standardText" >
            If you miss an appointment with Dr Hazlehurst or cancel short notice it is up to Dr Hazlehurst’s discretion if you are allowed to book another appointment with her.
            </p>
            <p className="standardText" >
            If you miss two appointments or cancel two appointments with Dr Hazlehurst short notice you will not be allowed to book in with Dr Hazlehurst again.
            </p>
            <br />
            <h2>Consultations</h2>
            <p className="standardText" >Please arrive for your appointment at least 10 minutes in advance of your appointment time. 
                <b> Late arrival</b> may result in reduced consultation time, treatment time or you may have to
                forfeit the appointment.
            </p>
            <br />
            <h2>Obligation to perform a treatment</h2>
            <p className="standardText" >Even if you wish to have particular treatment, if Dr Hazlehurst believes the interventions she
            provides are unlikely to deliver the desired outcome or if a treatment will not be in your best
            interest, she will decline to perform treatment and will discuss with you the other options
            available.</p>
            
            <br />
            <h2>Complaints</h2>
            <p className="standardText" >In the unlikely event of a complaint. Please feel free to discuss your concerns and any issues
            you may have so we can help you with your concerns.</p>

            <br />
            <h2>Complications</h2>
            <p className="standardText" >Injectable procedures have well documented complications, although these are rare, Dr
                Hazlehurst will bring these to your attention at the time of your consultation and treatment.
                They will also be documented on your consent form.</p>
            <p className="standardText" >
                If you are at increased risk this will be brought to your attention before proceeding with any
                treatment.
            </p>
            <br />
            <p className="standardText" >
                It is therefore very important that you update Dr Hazlehurst with any changes in your medical
                history, including recent pregnancy, breast feeding, any surgery, infections, past or future
                dental work, all recent medication and herbal supplements so she can fully assess your risk
                and discuss it with you.
            </p>
            <br />
            <p className="standardText" >
                Dr Hazlehurst will make sure you have access to help and advice 7 days a week in case you
                have any concerns. It is important you get in touch if you develop any pain, significant
                unexpected swelling or blistering or anything else unusual that you were not expecting.
            </p>
            <br />
            <p className="standardText" >
                If it is out of hours please contact NHS 111. If you are very unwell please call 999.
                Dr Hazlehurst has been trained in the management of complications and attends regular
                training and updates to make sure she is up to date with the latest guidance on managing
                complications.
            </p>
            <br />
            <p className="standardText" >
                Should you develop any complication Dr Hazlehurst will treat it to the best of her ability,
                knowledge and training. She will also get advice from her colleagues where appropriate, as
                most complications can be managed conservatively.
            </p>
            <p className="standardText" >
                Most of the time she will treat you without further cost, with no charge for clinic reviews
                (although you may have to pay for a private prescription at your chemist unless your GP is
                happy to prescribe for you).
            </p>
            <br />
            <p className="standardText" >
                In the very unlikely event of a complication being more serious and needing further specialist
                treatment, Dr Hazlehurst will arrange for you to be seen, assessed and treated in the NHS.
                Please note that private medical insurance may not cover complications arising from cosmetic
                work.
            </p>

            </div>
            </div>
            


        </div>
    )
}


export default Terms;