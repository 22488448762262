import './gallery.css';

import GalleryItem from './galleryItem';

const Gallery = (props) => {
  
    return (

    <ul className="image-gallery">
      {props.galleryItems.map((item) =>{
        return(
          <GalleryItem url={item.url} description={item.description}/>        
        )
      })}
    </ul>
    )
}

export default Gallery;