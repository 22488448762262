
class InstagramService {

  blogs = [
    {
      "id": 1,
      "title": "Sample Blog Post",
      "slug": "sample-blog-post",
      "author": {
        "id": 123,
        "name": "John Doe",
        "email": "john.doe@example.com"
      },
      "content": "This is the content of the blog post. It can include text, images, and other media.",
      "tags": ["technology", "programming", "web development"],
      "created_at": "2024-01-10T12:34:56Z",
      "updated_at": "2024-01-10T14:45:30Z",
      "comments": [
        {
          "id": 101,
          "user": {
            "id": 456,
            "name": "Jane Smith"
          },
          "content": "Great post! Thanks for sharing.",
          "created_at": "2024-01-10T15:20:00Z"
        },
        {
          "id": 102,
          "user": {
            "id": 789,
            "name": "Bob Johnson"
          },
          "content": "I have a question about the third paragraph.",
          "created_at": "2024-01-10T16:45:00Z"
        }
      ]
    },
    {
      "id":2,
      "title": "Sample Blog Post 2",
      "slug": "sample-blog-post",
      "author": {
        "id": 123,
        "name": "John Doe",
        "email": "john.doe@example.com"
      },
      "content": "This is the content of the blog post. It can include text, images, and other media.",
      "tags": ["technology", "programming", "web development"],
      "created_at": "2024-01-10T12:34:56Z",
      "updated_at": "2024-01-10T14:45:30Z",
      "comments": [
        {
          "id": 101,
          "user": {
            "id": 456,
            "name": "Jane Smith"
          },
          "content": "Great post! Thanks for sharing.",
          "created_at": "2024-01-10T15:20:00Z"
        },
        {
          "id": 102,
          "user": {
            "id": 789,
            "name": "Bob Johnson"
          },
          "content": "I have a question about the third paragraph.",
          "created_at": "2024-01-10T16:45:00Z"
        }
      ]
    }


  ]

  getArticle() {
    return this.blogs;
  }
}




export default InstagramService