import React from "react";
import './contact.css';
import {pageview} from "react-ga";
import { useEffect } from 'react';
import { Header } from "../../components";
const Contact = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [result, setResult]  = React.useState();
    const [contact, setContact] = React.useState(
        {
            firstName: "",
            lastName: "",
            emailInfo: "",
            phoneNumber: "",
            comment: ""
        });


    const handleChanged = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.value.trim()
        })

    };

    useEffect(() =>{
        pageview(window.location.pathname)
    },[]);
    


    const handleSubmit = (e) => {
        
        e.preventDefault();
        setLoading(true);

        fetch("https://qtna8hfh21.execute-api.eu-west-2.amazonaws.com/SendEmail", {
            mode: "no-cors",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                senderName: contact.firstName + " " + contact.lastName,
                senderEmail: contact.emailInfo,
                message: contact.comment,
                phoneNumber: contact.phoneNumber,
                date: new Date()
            })
        }
        ).then((response) => {
            setResult(response);

        }).catch((err) =>{
            setResult(err);
        })
    }
    if(loading) return <Submitted onResult={result}/>

     return (
            <ContactForm onSubmit={handleSubmit} onChanged={handleChanged}/>
        )
}

const Submitted = (props) => {
    return  (
        <div className="container">
            <div className="row">
                <div className="min-height">
                    Thanks for your message, I will be in contact as soon as I can.
                </div>
            </div>
        </div>
    )
}

const ContactForm = (props) => {
    return (
        <div>
             <Header title="Contact" />
        
        <div className="container">
            <section>
                <div className="row">
                    <br />
                    <h1>Get in touch</h1>
                    <div>
                        <p>Do you have any quetions? </p> 
                        <p>Please do not hesitate to contact us directly. Our team will get in contact as soon as possible to help you.</p>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section>
                <form className="row g-3">
                    <div className="col-md-6">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" className="form-control" id="firstName" name="firstName" onChange={props.onChanged} required></input>
                    </div>
                    <div className="col-md-6">
                        <label for="lastname" class="form-label">Last Name</label>
                        <input type="text" className="form-control" id="lastname" name="lastName" onChange={props.onChanged} required></input>
                    </div>
                    <div className="col-md-8">
                        <label for="emailInfo" class="form-label">Email</label>
                        <input type="text" className="form-control" id="emailInfo" name="emailInfo" onChange={props.onChanged} required></input>
                    </div>
                    <div className="col-md-4">
                        <label for="phoneNumber" class="form-label">Phone Number</label>
                        <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" onChange={props.onChanged}></input>
                    </div>
                    <div className="col-md-12">
                        <label for="comments" class="form-label">Comments, Questions?</label>
                        <textarea className="form-control" id="comments" rows="3" name="comment" onChange={props.onChanged} required></textarea>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={props.onSubmit}>Submit</button>
                    </div>
                </form>
            </section>
            <br />
        </div>
        </div>
    )
}


export default Contact;