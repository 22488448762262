import { Header,  GalleryAWS } from '../../components';
const SkinBoosters = () => {
    return (
        <div>
            <Header title="Skin Boosters" ></Header>
            <div className="page-section">
                <div className="container">
                    <div className="max">
                        <section id="what-are-skin-boosters">
                            <div >
                                <h2>What are skin boosters? </h2>
                                <p className='standardText'>Skin Boosters are injectable products that are injected into the upper layers of the skin to give hydration,
                                    plumping and regeneration to the skin. Many skin boosters contain hyaluronic acid that is non cross linked
                                    (unlike dermal fillers that have cross-linked hyaluronic acid which add volume and shape).
                                </p>
                                <p className='standardText'>Newer skin boosters also contain other ingredients such as amino acids which stimulate the skin cells to
                                    produce more collagen.</p>
                            </div>
                        </section>
                     
                        <section id="what-are-skin-boosters-used-for">
                            <div>
                                <h2>What are skin boosters used for?</h2>
                                <ul>
                                    <li>Minimising the signs of skin aging.</li>
                                    <li>Skin repair.</li>
                                    <li>Adding density, immediate skin glow and hydration to the skin.</li>
                                    <li>Improving skin texture..</li>
                                    <li>Treating fine lines: face, accordion lines, neck, décolleté.</li>
                                    <li>Improves skin’s elasticity and radiance.</li>
                                    <li>Treating the under eye area: dark circles, crepey skin, lines.</li>
                                    <li>Hand rejuvenation.</li>
                                </ul>
                            </div>
                        </section>
                        <section id="what-does-the-skin-booster-treatment-involve">
                            <div>
                                <h2>What does the skin booster treatment involve?</h2>
                                <p className='standardText'>First a numbing cream will be applied prior to treatment to make the procedure comfortable and an
                                    antiseptic solution applied to cleanse the skin.</p>
                                <p className='standardText'>There are different techniques for injecting skin boosters depending on the area and the aims of the
                                    treatment. Skin boosters can be applied by injections, nappage (tiny superficial injections/mesotherapy)
                                    and by cannula.</p>

                                <p className='standardText'>Some skin boosters such as Seventy Hyal and Profilo can be injected via the BAP technique ( a 5 point
                                    lift) with 5 specific points on each side of the face and specific points for the neck.</p>
                                <p className='standardText'>The under eye area can be injected using the nappage technique (tiny superficial injections) or with
                                    cannula.</p>
                                <p className='standardText'>You will see noticeable improvement after one treatment but a course of treatments are usually required for
                                    maximum results e.g. for Seventy Hyall skin booster a course of 3 treatments 4 weeks apart and a top up
                                    treatment every 6 months.</p>
                                <p className='standardText'>The results come on very quickly within 2 days – 2 weeks depending on which product is used.</p>
                            </div>
                        </section>
                        <section id="side-effects">
                            <div>
                                <h2>What are the possible side effects?</h2>
                                <p className='standardText'>Bruising, swelling, infection, allergy.</p>
                            </div>
                        </section>
                        <section id="aftercare-advice">
                            <div>
                                <h2>Aftercare Advice</h2>
                                <p className='standardText'>After treatment don’t drink alcohol, don’t expose yourself to heat, don’t massage the area, no facials/
                                    massages for 2 weeks.</p>
                            </div>
                        </section>
                        <section id="booster-gallery">
                            <div>
                                <GalleryAWS bucketName="aesthetics-image-repository" prefix="SkinBoostersGallery" />
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SkinBoosters