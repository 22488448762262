import React, {  useEffect, useState } from 'react';
import './booking.css';
import axios from 'axios';

const Booking = () => {

  let missing ="missing";
  const [data, setBooking] = useState([])
  const [isLoading, setLoading] = useState(true);
  const [dateOptions, setOptions] = useState([]);;
  const [selectedDate, setSelectedDate] = useState("");
  const [booking, createBooking] = useState(
    { 
      type:missing, 
      location: missing, 
      date:missing, 
      time: missing,
      price:missing,
      customer: {
        title:missing,
        firstname:missing,
        lastname:missing,
        contactNumber:missing,
        email:missing
      }
     });
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);


  const bookingTypeChangeHandler = (event) => {
    const value  = parseInt(event.target.value);
    let test = products.filter(product => product.id === value)[0];
    createBooking(prevState => ({
      ...prevState,
      type:test.name,
      price: test.price
    }))
  }

  const setLocationHandler = (event) =>{
    const value = event.target.value;
    createBooking(prevState => ({
      ...prevState,
      location:value,
      time:missing,
      date:missing

    }))
    
  }
  const saveDateHandler = (event) =>{
    const value = event.target.value;
    setSelectedDate(value);
    createBooking(prevState => ({
      ...prevState,
      date:value,
      time:missing
    }))

  }

  const saveTimeHandler = (event) =>{
    const value = event.target.outerText;
    createBooking(prevState => ({
      ...prevState,
      time:value
    }))

  }



  useEffect(() => {
    axios
      .get('https://localhost:44318/api/TimeSlots')
      .then((res) => {
        setBooking(res.data);
        setOptions(res.data.dates)
        setSelectedDate(0);
        setLoading(false);
      });
      axios
      .get('https://localhost:44318/api/location/all')
      .then((res) => {
        setLocations(res.data);
      });

      axios.get('https://localhost:44318/api/product/all')
        .then((res) =>{
          setProducts(res.data)
        });
   
  },[]);

  if(isLoading){
    return (<div>loading...</div>)
  }

  const showLocations = () => {
    if(booking.type === missing)
    {
      return (<div></div>)
    }
    else
    {
      return (
        
        <div className='section'>
          <p>Please Select your prefered location.</p>
          <span>Available Locations</span>
          <select onChange={setLocationHandler}>
            { locations.map((item, index) => (
              <option key={index} value={item.name} >
                {item.name}
              </option>
          ))}
          </select>
        </div>
      )
    }

  }

  const showDates = () =>{
    if(booking.location===missing)
    {
      return (<div></div>)
    }
    else
    {
      return(
        <div className='section'>
          <h2>Available Dates</h2>
          <p>Pick an available date from the drop down menu</p>
          <PickADate SelectedDate={selectedDate} OnChange={saveDateHandler} Dates = {dateOptions} label = "Pick a Date"/>
        </div>
      )
    }
   
  }

  const showTimes = () =>{
    if(booking.date=== missing){
      return (<div></div>)
      
    }
    else{
      return <AvailableTimesSection OnClick={saveTimeHandler} Times={data.timeSlots.filter(slots=> slots.date.includes(selectedDate))} /> 
    }
    
  }
  const showBookingType = () => {
   
    return (<div className='section'>
      
      <p>Please pick the type of booking you want to make, from the list.</p>
      <select onChange={bookingTypeChangeHandler}>
        {products.map((item, index) => (
            <option  key={index} value={item.id}>
              {item.name} : £{item.price}
            </option>
        ))
      }
      </select>
    </div>)
  }

  const showCustomerDetails = () => {
    function titleChangedHandler(event) {
      createBooking(prevState => ({
        ...prevState,
        customer : {
          title:event.target.value,
          firstname: prevState.customer.firstname,
          lastname: prevState.customer.lastname,
          contactNumber: prevState.customer.contactNumber,
          email: prevState.customer.email
        }
      }))
    }

    function firstnameChangedHandler(event) {
      createBooking(prevState => ({
        ...prevState,
        customer : {
          title:prevState.customer.title,
          firstname: event.target.value,
          lastname: prevState.customer.lastname,
          contactNumber: prevState.customer.contactNumber,
          email: prevState.customer.email
          
        }
      }))
    }

    function lastnameChangedHandler(event) {
      createBooking(prevState => ({
        ...prevState,
        customer : {
          title: prevState.customer.title,
          firstname: prevState.customer.firstname,
          lastname: event.target.value,
          contactNumber: prevState.customer.contactNumber,
          email: prevState.customer.email
        }
      }))
    }

    function contactNumberChangedHandler(event) {
      createBooking(prevState => ({
        ...prevState,
        customer : {
          title: prevState.customer.title,
          firstname: prevState.customer.firstname,
          lastname: prevState.customer.lastname,
          contactNumber:  event.target.value,
          email: prevState.customer.email
        }
      }))
    }

    function emailChangedHandler(event) {
      createBooking(prevState => ({
        ...prevState,
        customer : {
          title: prevState.customer.title,
          firstname: prevState.customer.firstname,
          lastname: prevState.customer.lastname,
          contactNumber: prevState.customer.contactNumber,
          email: event.target.value,
        }
      }))
    }

    if(booking.time === missing)
    {
      return (<div></div>)
    }
    else
    {
      return (
        <div className="section">
          <h2>Customer Details</h2>
          <div><span>Title</span><span><input name="title" type ="text" onChange={titleChangedHandler}/></span></div>
          <div><span>Firstname</span><span><input name="firstname" type ="text" onChange={firstnameChangedHandler} /></span></div>
          <div><span>Lastname</span><span><input type ="text" onChange={lastnameChangedHandler}  /></span></div>
          <div><span>Contact Number</span><span><input type ="text" onChange={contactNumberChangedHandler} /></span></div>
          <div><span>Email Address</span><span><input type ="text"onChange={emailChangedHandler} /></span></div>
        </div>
      )
    }
  }

  const review = () => {
    if(  booking.type === missing 
      || booking.date === missing
      || booking.time === missing
      || booking.location === missing
      || booking.customer.title===missing 
      || booking.customer.firstname.lmissing 
      || booking.customer.lastname ===missing 
      || booking.customer.contactNumber===missing 
      || booking.customer.email === missing ){
      return (<div></div>)
    }
    return (
      <div className='section'>
        <table className="table">
          <tbody>
            <tr><td>Booking Type</td><td>{booking.type}</td></tr>
            <tr><td>Location:</td><td>{booking.location}</td></tr>
            <tr><td>Date Time</td><td>{booking.date} at { booking.time}</td></tr>
            <tr><td>Price</td><td>{booking.price}</td></tr>
            <tr><td>details</td><td>{booking.customer.title} {booking.customer.firstname} { booking.customer.lastname}</td></tr>
            <tr><td>contact number</td><td>{booking.customer.contactNumber}</td></tr>
            <tr><td>email</td><td>{booking.customer.email}</td></tr>
          </tbody>
        </table>
      </div>
    )
  }



  const complete = () => {
    function confirmAppointment(){
      alert('booking confirmed!')
    }

    
    return (
      <div className='section'><button onClick={confirmAppointment} >Confirm</button></div>
    )
  }


  const Init = () => {
    return (
    <div className ="container">
      <h2>Book an Appointment</h2>
      <div>{showBookingType()}</div>
      <div>{showLocations()}</div>
      <div>{showDates()}</div>
      <div>{showTimes()}</div>
      <div>{showCustomerDetails()}</div>
      <div>{review()}</div>
      <div>{complete()}</div>
    </div>
    )
  }

  return (
   <div>{Init()}</div>
  )
 
}


const  AvailableTimesSection = ((props) => {
  return (
    <div className='section'><h2>Available Times</h2>
      <p>Please select a time slot from the available times below.</p>
      <AvailableTimes Times={props.Times} OnClick={props.OnClick}  />
  </div>
  )
})

const PickADate = ( (props) => {
  return(
    <div className='section'>
      <span>{props.label}</span>
      <select value={props.SelectedDate} onChange={props.OnChange} >
        {props.Dates.map((item, index) => (
          <option key={index} value={item} >
            {item}
          </option>
        ))}
    </select>
    </div>
  )
})

const AvailableTimes = ((props) => {
  const [isActive, setIsActive] = useState()

  function handleClick(event){
    if(isActive!==undefined)
    {
      isActive.classList.remove("grid-item-selected")
    }
    event.target.classList.add("grid-item-selected");
    setIsActive(event.target);
    props.OnClick(event)
  }
  return (
    <div className='section'>
      <div className="grid-container">
        {
          props.Times.map((ts, index)=> 
            <div key={index} className="grid-item" onClick={handleClick} value={ts.time}>{ts.time}</div>
          )
        }
      </div>
    </div>
  )
})


export default Booking