import Pip from '../pip/pip';
import './socialMedia-rightPanel.css';
import facebookLogo from '../../assets/social/f_logo_RGB-White_100.png';
import instagramIcon from '../../assets/social/Instagram_Glyph_White.png';
const SocialMediaRightPanel = () => {

    
    return (
    <div className="socialMedia-box">
        <Pip className="socialMedia-box pip" imageSource={facebookLogo} handle="https://www.facebook.com/DrHazlehurstAesthetics/" alt="facebook handle" />
        <Pip className="socialMedia-box pip" imageSource={instagramIcon} handle="https://www.instagram.com/drhazlehurstaesthetics/" alt="instagram handle" />
    </div>
    )
}

export default SocialMediaRightPanel;