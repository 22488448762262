import AWS from 'aws-sdk';

class AWSS3BucketServices{

    s3;
   
    constructor(){
        AWS.config.update({
            accessKeyId:process.env.REACT_APP_AWS_API_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET,
            region:process.env.REACT_APP_AWS_REGION
          })

          this.s3 = new AWS.S3();
          
    }

    async getItemsInBucket(bucketName, prefix){
        const params = {
            Bucket:bucketName,
            Prefix: prefix
        };
        const responseImages = await this.s3.listObjectsV2(params).promise();
        return responseImages.Contents;
    }

    async getObjectTagsList(bucketName, key){
       
        
        var params = {
            Bucket:bucketName,
            Key: key
        };

        const responseMetas = await this.s3.getObjectTagging(params).promise();
        console.log(responseMetas);
        return responseMetas.TagSet;

    }



}

export default AWSS3BucketServices