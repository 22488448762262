import { Header, Gallery, GalleryAWS } from '../../components';
import skinTag from '../../assets/skin_tag.jpg';
import facialThread from '../../assets/facialThreadVeins.jpg';
import campbeldemorgan from '../../assets/campbelldemorganspots.jpg';

import './skinLesion.css';
const SkinLegion = () => {
    return (
        <div>
            <Header title="Skin Lesion Removal with Electrocautery" ></Header>
            <div className="page-section">
                <div className="container">
                    <div className="max">
                        <section id="what can be removed">
                            <div >
                                <h2>What can be Removed by Electrocautery? </h2>
                                <ul>
                                    <li>Skin tags.</li>
                                    <li>Facial thread Veins.</li>
                                    <li>Spider naevus.</li>
                                    <li>Campbell de Morgan spots (blood spots).</li>
                                    <li>Milia.</li>
                                </ul>
                            </div>
                        </section>
                        <div className="spacer-2"></div>
                        <section id="what_is_a_skintag">
                            <div className="flex-item lesion">
                                <h3>What are Skin Tags? </h3>
                                <img src={skinTag}  width="200px" ></img>
                                <p className='standardText'>Skin tags are small flesh coloured or brown growths that hang from the skin. They are not harmful lesions.</p>
                            </div>
                        </section>
                        
                        <section id="what_are_facial_thread_veins">
                            <div className="flex-item lesion">
                                <h3>What are Facial Thread Veins?</h3>
                                <img src={facialThread}  width="200px" ></img>
                                <p className='standardText'>Thread veins are tiny veins on the surface of the skin on the face
                                    that have a web like pattern.</p>
                            </div>
                        </section>
                        <section id="what_are_facial_thread_veins">
                            <div className="flex-item lesion2">
                                <h3>What are Spider Naevus?</h3>
                                <p className='standardText'>Spider naevi are a central dilated blood vessel with small
                                    capillaries radiating out from them resembling spider legs.</p>
                            </div>
                        </section>
                        <section id="what_are_milia">
                            <div className="flex-item lesion2">
                                <h3>What are Milia?</h3>
                                <p className='standardText'>Milia are tiny little bumps that are white. They are very common and often seen around the
                                    eyes and upper cheeks.</p>
                            </div>
                        </section>
                        <section id="what_are_campbelldemorgan_spots">
                            <div className="flex-item lesion">
                                <h3>What are Campbell de Morgan spots?</h3>
                                <img src={campbeldemorgan}  width="200px" ></img>
                                <p className='standardText'>Campbell de Morgan spots are a vascular blemish with an appearance of a blood filled spot.
                                            They are harmless and often found on the trunk of the body but also on the face and limbs.</p>
                            </div>
                        </section>
                        <div className="spacer-2"></div>
                        <section id="suitable_for_treatment">
                            <div>
                                <h2>Who is suitable for Treatment? </h2>
                                <p className='standardText'>Treatment cannot be performed if any of the following apply.</p>
                                <ul>
                                    <li>Aged under 18 years.</li>
                                    <li>Cochlear implant.</li>
                                    <li>Pacemaker.</li>
                                    <li>Epilepsy.</li>
                                    <li>Keloid Scaring.</li>
                                    <li>Metal pins or plates in the area to be treated.</li>
                                </ul>
                            </div>
                        </section>
                        <div className="spacer-2"></div>
                        <section id="skin-lesion-gallery">
                            <div>
                                <GalleryAWS bucketName="aesthetics-image-repository" prefix="SkinLesionGallery" />
                            </div>
                        </section>
                     
                    </div>
                </div>
            </div>
        </div>)
}

export default SkinLegion