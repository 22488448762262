import React from 'react'
import './cryo.page.css';
import { Header } from '../../components';
import { useEffect } from 'react';
import { pageview } from 'react-ga';
import homeImage from '../../assets/cryo_what_can_I_treat.png';
const CryotherapyPage = () => {

    useEffect(() => {
        pageview(window.location.pathname)
    }, []);

    return (
        <div>
            <Header title="Cryotherapy" shadow="false" />
            <div className="page-section">
                <div className="container">
                    <div className="max">
                        <h1>Cryotherapy</h1>
                        <br />
                        <section id="What is Cryotherapy">
                            <div className="row">
                                <div className="col-md-7">
                                    <h2>What is Cryotherapy?</h2>
                                    <p className='standardText'>
                                        Cryotherapy is the use of extreme cold to cause controlled destruction of unwanted tissue in a precise application method.
                                    </p>
                                    
                                    <h2>How does cryotherapy work?</h2>
                                    <p className='standardText'>
                                    Cryotherapy works by causing the water in the skin cells to freeze, the water expands on freezing, fracturing the frozen cell walls causing cell death.
                                    </p>
                                    <h2>What’s the difference between different types of cryotherapy?</h2>
                                    <p className='standardText'>
                                    <ul>
                                        <li>Traditional liquid Nitrogen is very destructive to the skin and generally causes a wound.</li>
                                        <li>Co2 devices now found in pharmacies and supermarkets only reach -20C, do not cause cell necrosis so are often ineffective.</li>
                                        <li>Dr Hazlehurst uses CryoIQ Cryotherapy pens that contain nitrous oxide gas and are CE marked and FDA approved. They reach a freeze effect on the skin of -47C which is highly effective without being overly damaging to the skin.</li>
                                        <li>Dr Hazlehurst also uses Hydrozid Cryotherapy medical grade cryotherapy.</li>
                                    </ul>
                                    <img className="imgborder image-100"  src={homeImage} alt="photograph of dr hazlehurst in her clinic" />
                                    </p>
                                    <h2>Which lesions can be treated with cryotherapy?</h2>
                                    <p className='standardText'>
                                    <ul>
                                        <li>Warts</li>
                                        <li>Verrucas</li>
                                        <li>Seborrhoeic keratosis</li>
                                        <li>Benign pigment spots / patches</li>
                                        <li>Skin tags</li>
                                    </ul>
                                    </p>
                                    <h2>What are the advantages?</h2>
                                    <p className='standardText'>
                                    <ul>
                                        <li>Quick treatment: several seconds to a minute.</li>
                                        <li>Minimal discomfort.</li>
                                        <li>No downtime.</li>
                                        <li>Highly effective.</li>
                                        <li>Follow up treatment often not required.</li>
                                    </ul>
                                    </p>
                                    <h2>What is the cost of treatment?</h2>
                                    <p className='standardText'>
                                    <ul>
                                        <li>1 lesion from £100.</li>
                                        <li>Follow up treatment from £50.</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CryotherapyPage