import './banner.css';

const Banner = (props) => {

    return (
        <div className="banner-container">
            <div className="banner-space"></div>
            <div className="banner-content">
            {
                props.bannerData.map((item, i) =>{
                    return(
                        <div className="banner-item" key={i}>
                            <i className={item.icon}></i>
                            <h4>{item.title}</h4>
                            <p>{item.content}</p>
                        </div>
                    )
                })
            }</div>
            <div className="banner-space"></div>
        </div>
    )

}

export default Banner;